import React from 'React';
import styled from 'styled-components'
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE_COLOR, HEADER_COLOR } from './constants';
import { darken } from 'polished'
import { Link } from 'gatsby';

export const Button = styled.button<{ color?: "primary" | "secondary", size?: "small" | "large" | "default" }>`
  background-color: ${props => (props.color === "primary" ? PRIMARY_COLOR : SECONDARY_COLOR)};
  color: ${props => (props.color === "primary" ? WHITE_COLOR : HEADER_COLOR)};
  min-height: ${props => (props.size === "large" ? `48px` : props.size === "small" ? `35px` : `40px`)};
  border-radius: 30px;
  padding: ${props => (props.size === "large" ? `16px 28px` : props.size === "small" ? `10px 16px` : `12px 24px`)};
  font-size: ${props => (props.size === "large" ? `18px` : props.size === "small" ? `13px` : `14px`)};
  border: 0;
  min-width: 100px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 16px;
  & svg {
    height: ${props => (props.size === "large" ? `28px` : props.size === "small" ? `16px` : `24px`)};
    max-width: ${props => (props.size === "large" ? `28px` : props.size === "small" ? `16px` : `24px`)};
    margin-right: 8px;
    color: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    & path {
      fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    }
    & g {
      fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    }
    & rect {
      fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    }
  }
  :hover {
    background-color: ${props => (props.color === "primary" ? darken(0.1, PRIMARY_COLOR) : darken(0.1, SECONDARY_COLOR))};
  }
  :active {
    background-color: ${props => (props.color === "primary" ? darken(0.15, PRIMARY_COLOR) : darken(0.15, SECONDARY_COLOR))};
    transform: translate3d(0, 4px, 0);
  }
  :focus {
    background-color: ${props => (props.color === "primary" ? darken(0.15, PRIMARY_COLOR) : darken(0.15, SECONDARY_COLOR))};
  }
`;

export const LinkButton = styled.a<{ color?: "primary" | "secondary", size?: "small" | "large" | "default" }>`
  background-color: ${props => (props.color === "primary" ? PRIMARY_COLOR : SECONDARY_COLOR)};
  color: ${props => (props.color === "primary" ? WHITE_COLOR : HEADER_COLOR)};
  min-height: ${props => (props.size === "large" ? `48px` : props.size === "small" ? `35px` : `40px`)};
  border-radius: 30px;
  padding: ${props => (props.size === "large" ? `16px 28px` : props.size === "small" ? `10px 16px` : `12px 24px`)};
  font-size: ${props => (props.size === "large" ? `18px` : props.size === "small" ? `13px` : `14px`)};
  border: 0;
  min-width: 100px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  & svg {
    height: ${props => (props.size === "large" ? `24px` : props.size === "small" ? `16px` : `20px`)};
    max-width: ${props => (props.size === "large" ? `24px` : props.size === "small" ? `16px` : `20px`)};
    margin-right: 8px;
    color: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    & path {
      fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    }
    & g {
      fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    }
    & rect {
      fill: ${props => (props.color === "primary" ? WHITE_COLOR : PRIMARY_COLOR)};
    }
  }
  :hover {
    background-color: ${props => (props.color === "primary" ? `${darken(0.1, PRIMARY_COLOR)} !important` : `${darken(0.1, SECONDARY_COLOR)} !important`)};
    color: ${props => (props.color === "primary" ? `${WHITE_COLOR} !important` : `${HEADER_COLOR} !important`)};
  }
  :active {
    color: ${props => (props.color === "primary" ? `${WHITE_COLOR} !important` : `${HEADER_COLOR} !important`)};
    transform: translate3d(0, 4px, 0);
  }
  :focus {
    background-color: ${props => (props.color === "primary" ? darken(0.15, PRIMARY_COLOR) : darken(0.15, SECONDARY_COLOR))};
  }
`;

export const InternalLinkButtonWrapper = styled(Link)`
  text-decoration: none;
`

export const Card = styled.div<{ color?: "primary" | "secondary", size?: "small" | "large" | "default" }>`
  border: 1px solid #D0D1D4;
  border-radius: 18px;
  min-height: 100px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 18px 24px;
  box-sizing: border-box;
`

export const Row = styled.div<{ align?: "center" | "flex-start" | "flex-end", justify?: "center" | "flex-start" | "flex-end" }>`
  display: flex;
  justify-content: ${props => (props.justify)};;
  align-items: ${props => (props.align)};
  flex-direction: row;
`

export const Column = styled.div<{ align?: "center" | "flex-start" | "flex-end", justify?: "center" | "flex-start" | "flex-end" }>`
  display: flex;
  justify-content: ${props => (props.justify)};;
  align-items: ${props => (props.align)};
  flex-direction: column;
`