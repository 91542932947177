export const PRIMARY_COLOR = "#FF2A4C";
export const SECONDARY_COLOR = "#FFFFFF";

export const HEADER_COLOR = "#333E64";
export const TEXT_COLOR = "#606977";

export const WHITE_COLOR = "#FFFFFF";

export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
}